import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginCounter from './components/LoginCounter.vue'

Vue.use(VueRouter)

const routes = [
    { path: '/logincounter', component: LoginCounter },
    { path: '/', component: { template: '<div></div>' } } // Lege component voor de hoofdpagina
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
