<template>
  <div id="app" class="bg-dark text-white min-h-screen">
    <header class="py-4 px-6 bg-black flex justify-center items-center">
      <div class="cinema-visits" v-if="showCinemaCount">
        <span class="text-4xl font-bold">Bioscoopbezoekers: {{ cinemaCount }}</span>
      </div>
    </header>

    <main>
      <router-view
          @count-updated="updateCinemaCount"
          @toggle-count-visibility="setCinemaCountVisibility"
          @release-date-updated="updateReleaseDate"
      ></router-view>
      <!-- HeroSection ontvangt de releaseDate als prop -->
      <HeroSection :releaseDate="releaseDate" />
      <!-- Lazy loaded components -->
      <StorySection />
      <TicketsSection />
      <PhotoCarousel />
    </main>

    <footer class="py-4 px-6 bg-black text-center">
      <img :src="wweLogo" alt="WWE Logo" class="mx-auto mb-4" style="max-width: 150px;" />
      <p>&copy; 2024 Loverboy: Emoties Uit. Alle rechten voorbehouden.</p>
      <div class="mt-2">
        <a href="#" class="text-white hover:text-gray-300 mx-2">Privacybeleid</a>
        <a href="#" class="text-white hover:text-gray-300 mx-2">Gebruiksvoorwaarden</a>
        <a href="#" class="text-white hover:text-gray-300 mx-2">Contact</a>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from './axiosConfig';  // Gebruik de aangepaste axios configuratie
import HeroSection from './components/HeroSection.vue';

// Lazy loading components
const StorySection = () => import('./components/StorySection.vue');
const PhotoCarousel = () => import(/* webpackChunkName: "photo-carousel" */ './components/PhotoCarousel.vue');
const TicketsSection = () => import(/* webpackChunkName: "tickets-section" */ './components/TicketsSection.vue');

import LoginCounter from './components/LoginCounter.vue';
import WWELogo from '/src/assets/wwe.svg';

Vue.use(VueRouter);

const routes = [
  { path: '/logincounter', component: LoginCounter },
  { path: '/', component: { template: '<div></div>' } } // Lege component voor de hoofdpagina
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default {
  name: 'App',
  router,
  components: {
    HeroSection,
    StorySection, // Lazy loaded
    TicketsSection,
    PhotoCarousel, // Lazy loaded
  },
  data() {
    return {
      wweLogo: WWELogo,
      cinemaCount: 0,
      showCinemaCount: true, // Deze waarde wordt opgehaald van de server
      releaseDate: 'Nu in de bioscoop', // Startwaarde voor de release datum
    };
  },
  methods: {
    async fetchCinemaCount() {
      try {
        const response = await axios.get('/get-count');
        this.cinemaCount = response.data.count;
      } catch (error) {
        console.error('Error fetching cinema count:', error);
      }
    },
    async fetchCinemaVisibility() {
      try {
        const response = await axios.get('/cinema-visibility');
        this.showCinemaCount = response.data.visible;
      } catch (error) {
        console.error('Error fetching cinema visibility:', error);
      }
    },
    updateCinemaCount(newCount) {
      this.cinemaCount = newCount;
    },
    async fetchReleaseDate() {
      try {
        const response = await axios.get('/get-release-date');
        this.releaseDate = response.data.releaseDate;
      } catch (error) {
        console.error('Error fetching release date:', error);
      }
    },
    updateReleaseDate(newDate) {
      this.releaseDate = newDate;
    },
    setCinemaCountVisibility(isVisible) {
      this.showCinemaCount = isVisible;
    }
  },
  mounted() {
    this.fetchCinemaCount();
    this.fetchCinemaVisibility();
    this.fetchReleaseDate();
  }
};
</script>



<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  background-color: #1a1a1a;
  color: white;
}

#ticket-btn {
  background-color: #d48c37;
  top: 4rem;
  left: 1rem;
  color: white;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  z-index: 30;
  text-decoration: none;
}

#ticket-btn .iconify {
  font-size: 1.25rem;
}

#ticket-btn:hover {
  background-color: #a61b1b;
}

@media (max-width: 768px) {
  #ticket-btn {
    top: 1rem;
    left: 1rem;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    gap: 0.25rem;
  }

  #ticket-btn .iconify {
    font-size: 1rem;
  }
  .cinema-visits {
    text-align: center;
  }

  .text-4xl {
    font-size: 2.25rem; /* Zorgt voor een grotere tekst */
  }

  .font-bold {
    font-weight: bold;
  }
  .cinema-visits {
    color: white;
    font-weight: bold;
  }
}
</style>
