=<template>
  <div class="login-counter container mx-auto mt-8 p-4">
    <h1 class="text-2xl font-bold mb-4">Instellingen website</h1>

    <!-- Login Formulier -->
    <div v-if="!isLoggedIn">
      <input
          v-model="password"
          type="password"
          placeholder="Wachtwoord"
          class="border p-2 mr-2 text-black"
          @keyup.enter="login"
      >
      <button @click="login" class="bg-blue-500 text-white px-4 py-2 rounded">Login</button>
    </div>

    <!-- Ingelogde Acties -->
    <div v-else>
      <!-- Toggle Release Date -->
      <button @click="toggleReleaseDate" class="bg-purple-500 text-white px-4 py-2 rounded mb-4">
        {{ isAugustDate ? 'Switch to "Nu in de bioscoop"' : 'Switch to "29 augustus in de bioscoop"' }}
      </button>

      <!-- Update Cinema Count -->
      <input
          v-model.number="newCount"
          type="number"
          placeholder="Bioscoopbezoekers"
          class="border p-2 mr-2 text-black"
      >
      <button @click="updateCount" class="bg-green-500 text-white px-4 py-2 rounded mb-4">Update Teller</button>

      <!-- Toggle Cinema Count Visibility -->
      <button @click="toggleCounterVisibility" class="bg-yellow-500 text-white px-4 py-2 rounded">
        {{ showCount ? 'Verberg Teller' : 'Toon Teller' }}
      </button>
    </div>

    <p v-if="message" class="mt-2 text-red-500">{{ message }}</p>
  </div>
</template>

<script>
import axios from '../axiosConfig'; // Zorg ervoor dat je de aangepaste Axios configuratie gebruikt

export default {
  data() {
    return {
      password: '',
      isLoggedIn: false,
      isAugustDate: false, // Houdt bij of de huidige datum "29 augustus in de bioscoop" is
      newCount: null, // Voor het bijwerken van het aantal bioscoopbezoekers
      showCount: true, // Voor het bijhouden of de teller zichtbaar moet zijn
      message: ''
    };
  },
  methods: {
    async login() {
      console.log('Attempting to log in...');
      try {
        const response = await axios.post('/login', { password: this.password });
        if (response.data.success) {
          this.isLoggedIn = true;
          this.password = '';
          this.message = 'Ingelogd!';
          this.loadCounterVisibility(); // Laad de huidige zichtbaarheid bij inloggen
        } else {
          this.message = 'Incorrect wachtwoord';
        }
      } catch (error) {
        this.message = 'Serverfout of incorrect wachtwoord';
      }
    },
    async toggleReleaseDate() {
      this.isAugustDate = !this.isAugustDate;
      const newDate = this.isAugustDate ? '29 augustus in de bioscoop' : 'Nu in de bioscoop';
      console.log(`Toggling release date to: ${newDate}`);
      try {
        const response = await axios.post('/update-release-date', { releaseDate: newDate });
        if (response.data.success) {
          this.$emit('release-date-updated', newDate);
          this.message = 'Release datum bijgewerkt!';
        } else {
          this.message = 'Fout bij bijwerken van de release datum';
        }
      } catch (error) {
        this.message = 'Serverfout bij bijwerken van de release datum';
      }
    },
    async updateCount() {
      if (this.newCount !== null) {
        console.log(`Updating cinema count to: ${this.newCount}`);
        try {
          const response = await axios.post('/update-count', { newCount: this.newCount });
          if (response.data.success) {
            this.$emit('count-updated', response.data.count);
            this.message = 'Teller bijgewerkt!';
          } else {
            this.message = 'Fout bij bijwerken van de teller';
          }
        } catch (error) {
          this.message = 'Serverfout bij bijwerken van de teller';
        }
      } else {
        this.message = 'Voer een geldig aantal in';
      }
    },
    async toggleCounterVisibility() {
      this.showCount = !this.showCount;
      console.log(`Toggling cinema count visibility to: ${this.showCount}`);
      try {
        const response = await axios.post('/cinema-visibility', { visible: this.showCount });
        if (response.data.success) {
          this.$emit('toggle-count-visibility', this.showCount);
          this.message = this.showCount ? 'Teller zichtbaar gemaakt' : 'Teller verborgen';
        } else {
          this.message = 'Fout bij bijwerken van de zichtbaarheid';
        }
      } catch (error) {
        this.message = 'Serverfout bij bijwerken van de zichtbaarheid';
      }
    },
    async loadCounterVisibility() {
      console.log('Loading cinema count visibility...');
      try {
        const response = await axios.get('/cinema-visibility');
        this.showCount = response.data.visible;
      } catch (error) {
        this.message = 'Fout bij laden van zichtbaarheid';
      }
    }
  }
};
</script>
