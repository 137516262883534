import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:3000';

// Maak een nieuwe axios instance aan
const axiosInstance = axios.create({
    baseURL: API_URL,
});

// Voeg een request interceptor toe
axiosInstance.interceptors.request.use(request => {
    // Verwerking van het request kan hier worden toegevoegd als dat nodig is
    return request;
}, error => {
    return Promise.reject(error);
});

// Voeg een response interceptor toe
axiosInstance.interceptors.response.use(response => {
    // Verwerking van de response kan hier worden toegevoegd als dat nodig is
    return response;
}, error => {
    return Promise.reject(error);
});

export default axiosInstance;
