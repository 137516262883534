<template>
  <div class="hero-container relative">
    <!-- Cinema counter -->
    <div class="hero-wrapper absolute inset-0 z-0">
      <div class="overlay absolute inset-0 bg-black bg-opacity-50 z-10"></div>
      <div class="video-container absolute inset-0 overflow-hidden">
        <iframe
            src="https://player.vimeo.com/video/1000675999?dnt=1&loop=1&background=1&app_id=122963"
            frameborder="0"
            title="Loverboy Emoties Uit"
            class="absolute top-1/2 left-1/2 w-auto h-auto min-w-[150%] min-h-[150%] max-w-none transform -translate-x-1/2 -translate-y-1/2"
        ></iframe>
      </div>
    </div>

    <div class="hero relative z-20 flex flex-col items-center justify-between h-full py-12">
      <!-- Release date blijft bovenaan -->
      <div class="release-date text-white font-bold bg-black bg-opacity-70 py-3 px-6 rounded-lg">
        <span class="text-2xl">{{ releaseDate }}</span>
      </div>

      <!-- Centrale content -->
      <div class="site-width text-center flex flex-col items-center">
        <img
            :src="loverboyImage"
            alt="Loverboy: Emoties Uit"
            class="title-image mx-auto mb-12"
            loading="lazy"
        />
        <div class="action-buttons space-y-4">
          <a href="#tickets" class="fancy-btn koop-tickets-btn">
            <span class="btn-text">Koop Tickets</span>
            <span class="icon-wrapper">
              <span class="iconify" data-icon="ion:ticket" aria-hidden="true"></span>
            </span>
          </a>
          <button @click="openModal" class="fancy-btn trailer-btn">
            <span class="btn-text">Bekijk Trailer</span>
            <span class="icon-wrapper">
              <span class="iconify" data-icon="ion:play" aria-hidden="true"></span>
            </span>
          </button>
          <a :href="whatsappShareLink" target="_blank" class="fancy-btn share-btn">
            <span class="btn-text">Deel Website</span>
            <span class="icon-wrapper">
              <span class="iconify" data-icon="bi:share-fill" aria-hidden="true"></span>
            </span>
          </a>
        </div>
      </div>

      <!-- Footer sectie met Instagram link en scroll indicator -->
      <div class="hero-footer flex flex-col items-center space-y-4">
        <a href="https://www.instagram.com/loverboyemotiesuit/" target="_blank" rel="noopener noreferrer" class="instagram-link">
          <span class="iconify" data-icon="mdi:instagram" aria-hidden="true"></span>
          <span class="sr-only">Volg ons op Instagram</span>
        </a>
        <div class="scroll-indicator">
          <svg class="w-12 h-12 animate-pulse" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
          </svg>
        </div>
      </div>
    </div>

    <TrailerModal :isOpen="isModalOpen" @close="closeModal" />
  </div>
</template>

<script>
import loverboyImage from '@/assets/TITLE-LOVERBOY-EMOTIES-UIT.webp'
import TrailerModal from '@/components/TrailerModal.vue'

export default {
  name: 'HeroSection',
  components: {
    TrailerModal
  },
  props: {
    releaseDate: {
      type: String,
      required: true,
      default: 'Nu in de bioscoop'
    }
  },
  data() {
    return {
      loverboyImage,
      isModalOpen: false,
      whatsappShareLink: ''
    }
  },
  mounted() {
    this.$nextTick(this.generateWhatsAppLink);
  },
  methods: {
    generateWhatsAppLink() {
      const message = encodeURIComponent("Bekijk de nieuwste film Loverboy: Emoties Uit! ");
      const url = encodeURIComponent("https://www.loverboyemotiesuit.nl");
      this.whatsappShareLink = `https://wa.me/?text=${message}${url}`;
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    }
  }
}
</script>

<style scoped>
.hero-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.whatsapp-btn {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 50% !important;
  background-color: rgba(37, 211, 102, 0.7) !important;
  color: white !important;
  text-decoration: none !important;
  transition: background-color 0.3s ease !important;
}

.whatsapp-btn .iconify {
  font-size: 30px !important;
}

.whatsapp-btn:hover {
  background-color: rgba(30, 190, 87, 0.7) !important;
}
.hero-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container {
  position: absolute;
  top: -25%;
  left: -25%;
  width: 150%;
  height: 150%;
  overflow: hidden;
}
.share-btn {
  background: linear-gradient(45deg, #e53935, #f44336);
}

.share-btn:hover {
  background: linear-gradient(45deg, #f44336, #e53935);
}
.video-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.hero {
  position: relative;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.release-date {
  text-align: center;
  white-space: nowrap;
}

.site-width {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.title-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.fancy-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  border: none;
  border-radius: 50px; /* Afgeronde hoeken */
  cursor: pointer;
  transition: all 0.3s ease;
  width: 220px;
  height: 50px;
}
.icon-wrapper {
  display: inline-flex;
  margin-left: 10px; /* Ruimte tussen tekst en icoon */
}
.koop-tickets-btn {
  background: linear-gradient(45deg, #d48c37, #ffa500);
}

.koop-tickets-btn:hover {
  background: linear-gradient(45deg, #ffa500, #d48c37);
}
.share-btn {
  background: linear-gradient(45deg, #25D366, #1ebe57); /* WhatsApp groene kleur */
}

.share-btn:hover {
  background: linear-gradient(45deg, #1ebe57, #25D366);
}

.trailer-btn {
  background: linear-gradient(45deg, #1c91b9, #3498db);
}

.trailer-btn:hover {
  background: linear-gradient(45deg, #3498db, #1c91b9);
}

.hero-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.instagram-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  transition: all 0.3s ease;
}

.instagram-link:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: scale(1.1);
}

.instagram-link .iconify {
  font-size: 24px;
}

.scroll-indicator {
  color: rgba(255, 255, 255, 0.7);
  animation: fade 2s ease-in-out infinite;
}

@keyframes fade {
  0%, 100% { opacity: 0.3; }
  50% { opacity: 0.7; }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .video-container {
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
  }

  .video-frame {
    width: 300%;
    height: 300%;
  }

  .hero {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .release-date {
    font-size: 1.2rem;
  }

  .fancy-btn {
    width: 200px;
    height: 45px;
    font-size: 0.9rem;
  }

  .instagram-link {
    width: 40px;
    height: 40px;
  }

  .instagram-link .iconify {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .hero {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .release-date {
    font-size: 1rem;
    white-space: normal;
    width: 90%;
  }

  .title-image {
    margin-bottom: 1.5rem;
  }

  .action-buttons {
    gap: 0.75rem;
  }

  .fancy-btn {
    width: 180px;
    height: 40px;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }

  .hero-footer {
    gap: 0.75rem;
  }
}
</style>
