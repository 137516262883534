import Vue from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router' // Zorg ervoor dat je een router.js bestand hebt

Vue.config.productionTip = false

new Vue({
  router, // Voeg de router toe aan de Vue instantie
  render: h => h(App),
}).$mount('#app')
